import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="carrying-movement-and-exploration"></a><h2>Carrying, Movement, and Exploration</h2>
    <a id="carrying-capacity"></a><h3>CARRYING CAPACITY</h3>
    <p className="initial">Encumbrance rules determine how much a
character&#8217;s armor and equipment slow him or her down. Encumbrance comes
in two parts: encumbrance by armor and encumbrance by total weight.</p>
    <a id="encumberance-by-armor"></a><p><b>Encumbrance by Armor</b>: A character&#8217;s armor defines his
or her maximum Dexterity bonus to AC, armor check penalty, speed, and
running speed. Unless your character is weak or carrying a lot of gear,
that&#8217;s all you need to know. The extra gear your character carries
won&#8217;t slow him or her down any more than the armor already does.</p>
    <p>If your character is weak or carrying a lot of gear, however,
then you&#8217;ll need to calculate encumbrance by weight. Doing so is most
important when your character is trying to carry some heavy object.</p>
    <a id="weight"></a><p><b>Weight</b>: If you want to determine whether your
character&#8217;s gear is heavy enough to slow him or her down more than the
armor already does, total the weight of all the character&#8217;s items,
including armor, weapons, and gear. Compare this total to the
character&#8217;s Strength on Table: Carrying Capacity. Depending on how the
weight compares to the character&#8217;s carrying capacity, he or she may be
carrying a light, medium, or heavy load. Like armor, a character&#8217;s load
affects his or her maximum Dexterity bonus to AC, carries a check
penalty (which works like an armor check penalty), reduces the
character&#8217;s speed, and affects how fast the character can run, as shown
on Table: Carrying Loads. A medium or heavy load counts as medium or
heavy armor for the purpose of abilities or skills that are restricted
by armor. Carrying a light load does not encumber a character.</p>
    <p>If your character is wearing armor, use the worse figure (from
armor or from load) for each category. Do not stack the penalties.</p>
    <a id="lifting-and-dragging"></a><p><b>Lifting and Dragging</b>: A character can lift as much as
his or her maximum load over his or her head.</p>
    <p>A character can lift as much as double his or her maximum load
off the ground, but he or she can only stagger around with it. While
overloaded in this way, the character loses any Dexterity bonus to AC
and can move only 5 feet per round (as a full-round action).</p>
    <p>A character can generally push or drag along the ground as
much as five times his or her maximum load. Favorable conditions can
double these numbers, and bad circumstances can reduce them to one-half
or less.</p>
    <a id="bigger-and-smaller-creatures"></a><p><b>Bigger and Smaller Creatures</b>: The figures on Table:
Carrying Capacity are for Medium bipedal creatures. A larger bipedal
creature can carry more weight depending on its size category, as
follows: Large x2, Huge x4, Gargantuan x8, Colossal x16. A smaller
creature can carry less weight depending on its size category, as
follows: Small x3/4, Tiny x1/2, Diminutive x1/4, Fine x1/8.</p>
    <p>Quadrupeds can carry heavier loads than characters can.
Instead of the multipliers given above, multiply the value
corresponding to the creature&#8217;s Strength score from Table: Carrying
Capacity by the appropriate modifier, as follows: Fine x1/4, Diminutive
x1/2, Tiny x3/4, Small x1, Medium x1-1/2, Large x3, Huge x6, Gargantuan
x12, Colossal x24.</p>
    <a id="tremendous-strength"></a><p><b>Tremendous Strength</b>: For Strength scores not shown on
Table: Carrying Capacity, find the Strength score between 20 and 29
that has the same number in the &#8220;ones&#8221; digit as the creature&#8217;s Strength
score does and multiply the numbers in that for by 4 for every ten
points the creature&#8217;s strength is above the score for that row.</p>
    <a id="table-carrying-capacity"></a><p><b>Table: CARRYING CAPACITY</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th>Strength
Score</th>
          <th>Light Load</th>
          <th>Medium Load</th>
          <th>Heavy Load</th>
        </tr>
        <tr className="odd-row">
          <td>1</td>
          <td>3 lb. or less</td>
          <td>4&#8211;6 lb.</td>
          <td>7&#8211;10 lb.</td>
        </tr>
        <tr>
          <td>2</td>
          <td>6 lb. or less</td>
          <td>7&#8211;13 lb.</td>
          <td>14&#8211;20 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>3</td>
          <td>10 lb. or less</td>
          <td>11&#8211;20 lb.</td>
          <td>21&#8211;30 lb.</td>
        </tr>
        <tr>
          <td>4</td>
          <td>13 lb. or less</td>
          <td>14&#8211;26 lb.</td>
          <td>27&#8211;40 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>5</td>
          <td>16 lb. or less</td>
          <td>17&#8211;33 lb.</td>
          <td>34&#8211;50 lb.</td>
        </tr>
        <tr>
          <td>6</td>
          <td>20 lb. or less</td>
          <td>21&#8211;40 lb.</td>
          <td>41&#8211;60 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>7</td>
          <td>23 lb. or less</td>
          <td>24&#8211;46 lb.</td>
          <td>47&#8211;70 lb.</td>
        </tr>
        <tr>
          <td>8</td>
          <td>26 lb. or less</td>
          <td>27&#8211;53 lb.</td>
          <td>54&#8211;80 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>9</td>
          <td>30 lb. or less</td>
          <td>31&#8211;60 lb.</td>
          <td>61&#8211;90 lb.</td>
        </tr>
        <tr>
          <td>10</td>
          <td>33 lb. or less</td>
          <td>34&#8211;66 lb.</td>
          <td>67&#8211;100 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>11</td>
          <td>38 lb. or less</td>
          <td>39&#8211;76 lb.</td>
          <td>77&#8211;115 lb.</td>
        </tr>
        <tr>
          <td>12</td>
          <td>43 lb. or less</td>
          <td>44&#8211;86 lb.</td>
          <td>87&#8211;130 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>13</td>
          <td>50 lb. or less</td>
          <td>51&#8211;100 lb.</td>
          <td>101&#8211;150 lb.</td>
        </tr>
        <tr>
          <td>14</td>
          <td>58 lb. or less</td>
          <td>59&#8211;116 lb.</td>
          <td>117&#8211;175 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>15</td>
          <td>66 lb. or less</td>
          <td>67&#8211;133 lb.</td>
          <td>134&#8211;200 lb.</td>
        </tr>
        <tr>
          <td>16</td>
          <td>76 lb. or less</td>
          <td>77&#8211;153 lb.</td>
          <td>154&#8211;230 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>17</td>
          <td>86 lb. or less</td>
          <td>87&#8211;173 lb.</td>
          <td>174&#8211;260 lb.</td>
        </tr>
        <tr>
          <td>18</td>
          <td>100 lb. or less</td>
          <td>101&#8211;200 lb.</td>
          <td>201&#8211;300 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>19</td>
          <td>116 lb. or less</td>
          <td>117&#8211;233 lb.</td>
          <td>234&#8211;350 lb.</td>
        </tr>
        <tr>
          <td>20</td>
          <td>133 lb. or less</td>
          <td>134&#8211;266 lb.</td>
          <td>267&#8211;400 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>21</td>
          <td>153 lb. or less</td>
          <td>154&#8211;306 lb.</td>
          <td>307&#8211;460 lb.</td>
        </tr>
        <tr>
          <td>22</td>
          <td>173 lb. or less</td>
          <td>174&#8211;346 lb.</td>
          <td>347&#8211;520 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>23</td>
          <td>200 lb. or less</td>
          <td>201&#8211;400 lb.</td>
          <td>401&#8211;600 lb.</td>
        </tr>
        <tr>
          <td>24</td>
          <td>233 lb. or less</td>
          <td>234&#8211;466 lb.</td>
          <td>467&#8211;700 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>25</td>
          <td>266 lb. or less</td>
          <td>267&#8211;533 lb.</td>
          <td>534&#8211;800 lb.</td>
        </tr>
        <tr>
          <td>26</td>
          <td>306 lb. or less</td>
          <td>307&#8211;613 lb.</td>
          <td>614&#8211;920 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>27</td>
          <td>346 lb. or less</td>
          <td>347&#8211;693 lb.</td>
          <td>694&#8211;1,040 lb.</td>
        </tr>
        <tr>
          <td>28</td>
          <td>400 lb. or less</td>
          <td>401&#8211;800 lb.</td>
          <td>801&#8211;1,200 lb.</td>
        </tr>
        <tr className="odd-row">
          <td>29</td>
          <td>466 lb. or less</td>
          <td>467&#8211;933 lb.</td>
          <td>934&#8211;1,400 lb.</td>
        </tr>
        <tr>
          <td className="last-row">+10</td>
          <td className="last-row">x4</td>
          <td className="last-row">x4</td>
          <td className="last-row">x4</td>
        </tr>
      </tbody>
    </table>
    <a id="table-carrying-loads"></a><p><b>Table: CARRYING LOADS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th> 
          </th>
          <th> 
          </th>
          <th> 
          </th>
          <th colSpan="3" className="center">&#8211;&#8212;&#8212; Speed &#8212;&#8211;&#8212;</th>
        </tr>
        <tr>
          <th>Load</th>
          <th>Max Dex</th>
          <th>Check Penalty</th>
          <th>(30 ft.)</th>
          <th>(20 ft.)</th>
          <th>Run</th>
        </tr>
        <tr className="odd-row">
          <td>Medium</td>
          <td>+3</td>
          <td>&#8211;3</td>
          <td>20 ft.</td>
          <td>15 ft.</td>
          <td>x4</td>
        </tr>
        <tr>
          <td className="last-row">Heavy</td>
          <td className="last-row">+1</td>
          <td className="last-row">&#8211;6</td>
          <td className="last-row">20 ft.</td>
          <td className="last-row">15 ft.</td>
          <td className="last-row">x3</td>
        </tr>
      </tbody>
    </table>
    <a id="armor-and-encumberance-for-other-base-speeds"></a><h6>Armor and Encumbrance for Other Base Speeds</h6>
    <p className="initial">The table below provides reduced speed figures
for all base speeds from 20 feet to 100 feet (in 10-foot increments).</p>
    <a id="table-armor-and-encumberance-for-other-base-speeds"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>Base Speed</th>
          <th>Reduced Speed</th>
          <th>Base Speed</th>
          <th>Reduced Speed</th>
        </tr>
        <tr className="odd-row">
          <td>20 ft.</td>
          <td>15 ft.</td>
          <td>70 ft.</td>
          <td>50 ft.</td>
        </tr>
        <tr>
          <td>30 ft.</td>
          <td>20 ft.</td>
          <td>80 ft.</td>
          <td>55 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>40 ft.</td>
          <td>30 ft.</td>
          <td>90 ft.</td>
          <td>60 ft.</td>
        </tr>
        <tr>
          <td>50 ft.</td>
          <td>35 ft.</td>
          <td>100 ft.</td>
          <td>70 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>60 ft.</td>
          <td>40 ft.</td>
          <td>
          </td>
          <td>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="movement"></a><h3>MOVEMENT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">There are three movement scales, as follows.</p>
    <ul>
      <li>
        <p>Tactical, for combat, measured in feet (or squares) per
round.</p>
      </li>
      <li>
        <p>Local, for exploring an area, measured in feet per minute.</p>
      </li>
      <li>
        <p>Overland, for getting from place to place, measured in
miles per hour or miles per day.</p>
      </li>
    </ul>
    <a id="modes-of-movement"></a><p><b>Modes of Movement</b>: While moving at the different
movement scales, creatures generally walk, hustle, or run.</p>
    <a id="modes-of-movement-walk"></a><p><i>Walk</i>: A walk represents unhurried but purposeful
movement at 3 miles per hour for an unencumbered human.</p>
    <a id="modes-of-movement-hustle"></a><p><i>Hustle</i>: A hustle is a jog at about 6 miles per hour for
an unencumbered human. A character moving his or her speed twice in a
single round, or moving that speed in the same round that he or she
performs a standard action or another move action is hustling when he
or she moves.</p>
    <a id="modes-of-movement-run-x3"></a><p><i>Run (x3)</i>: Moving three times speed is a running pace
for a character in heavy armor. It represents about 9 miles per hour
for a human in full plate.</p>
    <a id="modes-of-movement-run-x4"></a><p><i>Run (x4)</i>: Moving four times speed is a running pace for
a character in light, medium, or no armor. It represents about 12 miles
per hour for an unencumbered human, or 8 miles per hour for a human in
chainmail.</p>
    <a id="tactical-movement"></a><h5>TACTICAL MOVEMENT</h5>
    <p className="initial">Use tactical movement for combat. Characters
generally don&#8217;t walk during combat&#8212;they hustle or run. A character who
moves his or her speed and takes some action is hustling for about half
the round and doing something else the other half.</p>
    <a id="hampered-movement"></a><p><b>Hampered Movement</b>: Difficult terrain, obstacles, or
poor visibility can hamper movement. When movement is hampered, each
square moved into usually counts as two squares, effectively reducing
the distance that a character can cover in a move. </p>
    <p>If more than one condition applies, multiply together all
additional costs that apply. (This is a specific exception to the
normal rule for doubling) </p>
    <p>In some situations, your movement may be so hampered that you
don&#8217;t have sufficient speed even to move 5 feet (1 square). In such a
case, you may use a full-round action to move 5 feet (1 square) in any
direction, even diagonally. Even though this looks like a 5-foot step,
it&#8217;s not, and thus it provokes attacks of opportunity normally. (You
can&#8217;t take advantage of this rule to move through impassable terrain or
to move when all movement is prohibited to you.)</p>
    <p>You can&#8217;t run or <a href="specialAttacks.html#charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>charge</a> through any square that
would hamper
your movement.</p>
    <a id="local-movement"></a><h5>LOCAL MOVEMENT</h5>
    <p className="initial">Characters exploring an area use local
movement, measured in feet per minute.</p>
    <a id="local-movement-walk"></a><p><b>Walk</b>: A character can walk without a problem on the
local scale.</p>
    <a id="local-movement-hustle"></a><p><b>Hustle</b>: A character can hustle without a problem on the
local scale. See Overland Movement, below, for movement measured in
miles per hour.</p>
    <a id="local-movement-run"></a><p><b>Run</b>: A character with a Constitution score of 9 or
higher can run for a minute without a problem. Generally, a character
can run for a minute or two before having to rest for a minute</p>
    <a id="overland-movement"></a><h5>OVERLAND MOVEMENT</h5>
    <p className="initial">Characters covering long distances
cross-country use overland movement. Overland movement is measured in
miles per hour or miles per day. A day represents 8 hours of actual
travel time. For rowed watercraft, a day represents 10 hours of rowing.
For a sailing ship, it represents 24 hours.</p>
    <a id="overland-movement-walk"></a><p><b>Walk</b>: A character can walk 8 hours in a day of travel
without a problem. Walking for longer than that can wear him or her out
(see Forced March, below).</p>
    <a id="overland-movement-hustle"></a><p><b>Hustle</b>: A character can hustle for 1 hour without a
problem. Hustling for a second hour in between sleep cycles deals 1
point of nonlethal damage, and each additional hour deals twice the
damage taken during the previous hour of hustling. A character who
takes any nonlethal damage from hustling becomes <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a>.</p>
    <p>A fatigued character can&#8217;t run or <a href="specialAttacks.html#charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>charge</a>
and takes a penalty
of &#8211;2 to Strength and Dexterity. Eliminating the nonlethal damage also
eliminates the fatigue.</p>
    <a id="overland-movement-run"></a><p><b>Run</b>: A character can&#8217;t run for an extended period of
time.</p>
    <p>Attempts to run and rest in cycles effectively work out to a
hustle.</p>
    <a id="overland-movement-terrain"></a><p><b>Terrain</b>: The terrain through which a character travels
affects how much distance he or she can cover in an hour or a day (see
Table: Terrain and Overland Movement). A highway is a straight, major,
paved road. A road is typically a dirt track. A trail is like a road,
except that it allows only single-file travel and does not benefit a
party traveling with vehicles. Trackless terrain is a wild area with no
paths.</p>
    <a id="forced-march"></a><p><b>Forced March</b>: In a day of normal walking, a character
walks for 8 hours. The rest of the daylight time is spent making and
breaking camp, resting, and eating.</p>
    <p>A character can walk for more than 8 hours in a day by making
a forced march. For each hour of marching beyond 8 hours, a
Constitution check (DC 10, +2 per extra hour) is required. If the check
fails, the character takes 1d6 points of nonlethal damage. A character
who takes any nonlethal damage from a forced march becomes <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a>.
Eliminating the nonlethal damage also eliminates the fatigue. It&#8217;s
possible for a character to march into unconsciousness by pushing
himself too hard.</p>
    <a id="mounted-movement"></a><p><b>Mounted Movement</b>: A mount bearing a rider can move at a
hustle. The damage it takes when doing so, however, is lethal damage,
not nonlethal damage. The creature can also be ridden in a forced
march, but its Constitution checks automatically fail, and, again, the
damage it takes is lethal damage. Mounts also become <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a> when they
take any damage from hustling or forced marches.</p>
    <p>See Table: Mounts and Vehicles for mounted speeds and speeds
for vehicles pulled by draft animals.</p>
    <a id="waterborne-movement"></a><p><b>Waterborne Movement</b>: See Table: Mounts and Vehicles for
speeds for water vehicles.</p>
    <a id="table-movement-and-distance"></a><p><b>Table: MOVEMENT AND DISTANCE</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>
          </th>
          <th colSpan="4" className="center">&#8212;&#8212;&#8212;&#8212;&#8212;&#8212;&#8212; Speed &#8212;&#8212;&#8211;&#8212;&#8212;&#8212;&#8212;</th>
        </tr>
        <tr>
          <th>
          </th>
          <th style={{
            "width": "15%"
          }}>15 feet</th>
          <th style={{
            "width": "15%"
          }}>20 feet</th>
          <th style={{
            "width": "15%"
          }}>30 feet</th>
          <th style={{
            "width": "15%"
          }}>40 feet</th>
        </tr>
        <tr className="odd-row">
          <td>One Round (Tactical)<sup>1</sup></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>Walk</td>
          <td>15 ft.</td>
          <td>20 ft.</td>
          <td>30 ft.</td>
          <td>40 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>Hustle</td>
          <td>30 ft.</td>
          <td>40 ft.</td>
          <td>60 ft.</td>
          <td>80 ft.</td>
        </tr>
        <tr>
          <td>Run (x3)</td>
          <td>45 ft.</td>
          <td>60 ft.</td>
          <td>90 ft.</td>
          <td>120 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>Run (x4)</td>
          <td>60 ft.</td>
          <td>80 ft.</td>
          <td>120 ft.</td>
          <td>160 ft.</td>
        </tr>
        <tr>
          <td>One Minute (Local)</td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr className="odd-row">
          <td>Walk</td>
          <td>150 ft.</td>
          <td>200 ft.</td>
          <td>300 ft.</td>
          <td>400 ft.</td>
        </tr>
        <tr>
          <td>Hustle</td>
          <td>300 ft.</td>
          <td>400 ft.</td>
          <td>600 ft.</td>
          <td>800 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>Run (x3)</td>
          <td>450 ft.</td>
          <td>600 ft.</td>
          <td>900 ft.</td>
          <td>1,200 ft.</td>
        </tr>
        <tr>
          <td>Run (x4)</td>
          <td>600 ft.</td>
          <td>800 ft.</td>
          <td>1,200 ft.</td>
          <td>1,600 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>One Hour (Overland)</td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>Walk</td>
          <td>1-1/2 miles</td>
          <td>2 miles</td>
          <td>3 miles</td>
          <td>4 miles</td>
        </tr>
        <tr className="odd-row">
          <td>Hustle</td>
          <td>3 miles</td>
          <td>4 miles</td>
          <td>6 miles</td>
          <td>8 miles</td>
        </tr>
        <tr>
          <td>Run</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>One Day (Overland)</td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>Walk</td>
          <td>12 miles</td>
          <td>16 miles</td>
          <td>24 miles</td>
          <td>32 miles</td>
        </tr>
        <tr className="odd-row">
          <td>Hustle</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td className="last-row">Run</td>
          <td className="last-row">&#8212;</td>
          <td className="last-row">&#8212;</td>
          <td className="last-row">&#8212;</td>
          <td className="last-row">&#8212;</td>
        </tr>
        <tr>
          <td colSpan="5">1 <i>Tactical movement is often measured
in squares on the battle grid (1 square = 5 feet) rather than feet.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="table-hampered-movement"></a><p><b>Table: HAMPERED MOVEMENT</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "300px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th>Condition</th>
          <th>Additional
Movement Cost</th>
        </tr>
        <tr className="odd-row">
          <td>Difficult terrain</td>
          <td>x2</td>
        </tr>
        <tr>
          <td>Obstacle<sup>1</sup></td>
          <td>x2</td>
        </tr>
        <tr className="odd-row">
          <td>Poor visibility</td>
          <td>x2</td>
        </tr>
        <tr>
          <td className="last-row">Impassable</td>
          <td className="last-row">&#8212;</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>May require a skill check</i></td>
        </tr>
      </tbody>
    </table>
    <p><b>Table: TERRAIN AND OVERLAND MOVEMENT</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>Terrain</th>
          <th style={{
            "width": "20%"
          }}>Highway</th>
          <th style={{
            "width": "20%"
          }}>Road or Trail</th>
          <th style={{
            "width": "20%"
          }}>Trackless</th>
        </tr>
        <tr className="odd-row">
          <td>Desert, sandy</td>
          <td>x1</td>
          <td>x1/2</td>
          <td>x1/2</td>
        </tr>
        <tr>
          <td>Forest</td>
          <td>x1</td>
          <td>x1</td>
          <td>x1/2</td>
        </tr>
        <tr className="odd-row">
          <td>Hills</td>
          <td>x1</td>
          <td>x3/4</td>
          <td>x1/2</td>
        </tr>
        <tr>
          <td>Jungle</td>
          <td>x1</td>
          <td>x3/4</td>
          <td>x1/4</td>
        </tr>
        <tr className="odd-row">
          <td>Moor</td>
          <td>x1</td>
          <td>x1</td>
          <td>x3/4</td>
        </tr>
        <tr>
          <td>Mountains</td>
          <td>x3/4</td>
          <td>x3/4</td>
          <td>x1/2</td>
        </tr>
        <tr className="odd-row">
          <td>Plains</td>
          <td>x1</td>
          <td>x1</td>
          <td>x3/4</td>
        </tr>
        <tr>
          <td>Swamp</td>
          <td>x1</td>
          <td>x3/4</td>
          <td>x1/2</td>
        </tr>
        <tr className="odd-row">
          <td>Tundra, frozen</td>
          <td>x1</td>
          <td>x3/4</td>
          <td>x3/4</td>
        </tr>
      </tbody>
    </table>
    <a id="table-mounts-and-vehicles"></a><p><b>Table: MOUNTS AND VEHICLES</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>Mount/Vehicle</th>
          <th>Per Hour</th>
          <th>Per Day</th>
        </tr>
        <tr className="odd-row">
          <td>Mount (carrying load)</td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Light horse or light warhorse</td>
          <td>6 miles</td>
          <td>48 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Light horse (151&#8211;450 lb.)<sup>1</sup></td>
          <td>4 miles</td>
          <td>32 miles</td>
        </tr>
        <tr>
          <td className="indent">Light warhorse (231&#8211;690 lb.)<sup>1</sup></td>
          <td>4 miles</td>
          <td>32 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Heavy horse or heavy warhorse</td>
          <td>5 miles</td>
          <td>40 miles</td>
        </tr>
        <tr>
          <td className="indent">Heavy horse (201&#8211;600 lb.)<sup>1</sup></td>
          <td>3-1/2 miles</td>
          <td>28 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Heavy warhorse (301&#8211;900 lb.)<sup>1</sup></td>
          <td>3-1/2 miles</td>
          <td>28 miles</td>
        </tr>
        <tr>
          <td className="indent">Pony or warpony</td>
          <td>4 miles</td>
          <td>32 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Pony (76&#8211;225 lb.)<sup>1</sup></td>
          <td>3 miles</td>
          <td>24 miles</td>
        </tr>
        <tr>
          <td className="indent">Warpony (101&#8211;300 lb.)<sup>1</sup></td>
          <td>3 miles</td>
          <td>24 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Donkey or mule</td>
          <td>3 miles</td>
          <td>24 miles</td>
        </tr>
        <tr>
          <td className="indent">Donkey (51&#8211;150 lb.)<sup>1</sup></td>
          <td>2 miles</td>
          <td>16 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Mule (231&#8211;690 lb.)<sup>1</sup></td>
          <td>2 miles</td>
          <td>16 miles</td>
        </tr>
        <tr>
          <td className="indent">Dog, riding</td>
          <td>4 miles</td>
          <td>32 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Dog, riding (101&#8211;300 lb.)<sup>1</sup></td>
          <td>3 miles</td>
          <td>24 miles</td>
        </tr>
        <tr>
          <td>Cart or wagon</td>
          <td>2 miles</td>
          <td>16 miles</td>
        </tr>
        <tr className="odd-row">
          <td>Ship</td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Raft or barge (poled or towed)<sup>2</sup></td>
          <td>1/2 mile</td>
          <td>5 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Keelboat (rowed)<sup>2</sup></td>
          <td>1 mile</td>
          <td>10 miles</td>
        </tr>
        <tr>
          <td className="indent">Rowboat (rowed)<sup>2</sup></td>
          <td>1-1/2 miles</td>
          <td>15 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Sailing ship (sailed)</td>
          <td>2 miles</td>
          <td>48 miles</td>
        </tr>
        <tr>
          <td className="indent">Warship (sailed and rowed)</td>
          <td>2-1/2 miles</td>
          <td>60 miles</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Longship (sailed and rowed)</td>
          <td>3 miles</td>
          <td>72 miles</td>
        </tr>
        <tr>
          <td className="indent">Galley (rowed and sailed)</td>
          <td>4 miles</td>
          <td>96 miles</td>
        </tr>
        <tr>
          <td colSpan="3">1 <i>Quadrupeds, such as horses, can carry
heavier loads than characters can. See Carrying Capacity, above, for
more information.</i></td>
        </tr>
        <tr>
          <td colSpan="3">2 <i>Rafts, barges, keelboats, and
rowboats are used on lakes and rivers. If going downstream, add the
speed of the current (typically 3 miles per hour) to the speed of the
vehicle. In addition to 10 hours of being rowed, the vehicle can also
float an additional 14 hours, if someone can guide it, so add an
additional 42 miles to the daily distance traveled. These vehicles
can&#8217;t be rowed against any significant current, but they can be pulled
upstream by draft animals on the shores.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="moving-in-three-dimensions"></a><h5>MOVING IN THREE DIMENSIONS</h5>
    <a id="tactical-aerial-movement"></a><h6>Tactical Aerial Movement</h6>
    <p className="initial">Once movement becomes three-dimensional and
involves turning in midair and maintaining a minimum velocity to stay
aloft, it gets more complicated. Most flying creatures have to slow
down at least a little to make a turn, and many are limited to fairly
wide turns and must maintain a minimum forward speed. Each flying
creature has a maneuverability, as shown on Table: Maneuverability. The
entries on the table are defined below.</p>
    <a id="tactical-aerial-movement-minimum-forward-speed"></a><p><i>Minimum Forward Speed</i>: If a flying creature fails to
maintain its minimum forward speed, it must land at the end of its
movement. If it is too high above the ground to land, it falls straight
down, descending 150 feet in the first round of falling. If this
distance brings it to the ground, it takes falling damage. If the fall
doesn&#8217;t bring the creature to the ground, it must spend its next turn
recovering from the stall. It must succeed on a DC 20 Reflex save to
recover. Otherwise it falls another 300 feet. If it hits the ground, it
takes falling damage. Otherwise, it has another chance to recover on
its next turn.</p>
    <a id="tactical-aerial-movement-hover"></a><p><i>Hover</i>: The ability to stay in one place while airborne.
    </p>
    <a id="tactical-aerial-movement-move-backward"></a><p><i>Move Backward</i>: The ability to move backward without
turning around.</p>
    <a id="tactical-aerial-movement-reverse"></a><p><i>Reverse</i>: A creature with good maneuverability uses up 5
feet of its speed to start flying backward.</p>
    <a id="tactical-aerial-movement-turn"></a><p><i>Turn</i>: How much the creature can turn after covering the
stated distance.</p>
    <a id="tactical-aerial-movement-turn-in-place"></a><p><i>Turn in Place</i>: A creature with good or average
maneuverability can use some of its speed to turn in place.</p>
    <a id="tactical-aerial-movement-maximum-turn"></a><p><i>Maximum Turn</i>: How much the creature can turn in any one
space. </p>
    <a id="tactical-aerial-movement-up-angle"></a><p><i>Up Angle</i>: The angle at which the creature can climb.</p>
    <a id="tactical-aerial-movement-up-speed"></a><p><i>Up Speed</i>: How fast the creature can climb.</p>
    <a id="tactical-aerial-movement-down-angle"></a><p><i>Down Angle</i>: The angle at which the creature can descend.</p>
    <a id="tactical-aerial-movement-down-speed"></a><p><i>Down Speed</i>: A flying creature can fly down at twice its
normal flying speed.</p>
    <a id="tactical-aerial-movement-between-down-and-up"></a><p><i>Between Down and Up</i>: An average, poor, or clumsy flier
must fly level for a minimum distance after descending and before
climbing. Any flier can begin descending after a climb without an
intervening distance of level flight.</p>
    <a id="table-maneuverability"></a><p><b>Table: MANEUVERABILITY</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "600px"
    }}>
      <tbody>
        <tr>
          <th> 
          </th>
          <th colSpan="5" className="center">Maneuverability</th>
        </tr>
        <tr>
          <th> 
          </th>
          <th style={{
            "width": "14%"
          }}>Perfect</th>
          <th style={{
            "width": "14%"
          }}>Good</th>
          <th style={{
            "width": "14%"
          }}>Average</th>
          <th style={{
            "width": "14%"
          }}>Poor</th>
          <th style={{
            "width": "14%"
          }}>Clumsy</th>
        </tr>
        <tr className="odd-row">
          <td>Minimum forward speed</td>
          <td>None</td>
          <td>None</td>
          <td>Half</td>
          <td>Half</td>
          <td>Half</td>
        </tr>
        <tr>
          <td>Hover</td>
          <td>Yes</td>
          <td>Yes</td>
          <td>No</td>
          <td>No</td>
          <td>No</td>
        </tr>
        <tr className="odd-row">
          <td>Move backward</td>
          <td>Yes</td>
          <td>Yes</td>
          <td>No</td>
          <td>No</td>
          <td>No</td>
        </tr>
        <tr>
          <td>Reverse</td>
          <td>Free</td>
          <td>&#8211;5 ft.</td>
          <td>No</td>
          <td>No</td>
          <td>No</td>
        </tr>
        <tr className="odd-row">
          <td>Turn</td>
          <td>Any</td>
          <td>90&ordm;/5 ft.</td>
          <td>45&ordm;/5 ft.</td>
          <td>45&ordm;/5 ft.</td>
          <td>45&ordm;/10 ft.</td>
        </tr>
        <tr>
          <td>Turn in place</td>
          <td>Any</td>
          <td>+90&ordm;/&#8211;5 ft.</td>
          <td>+45&ordm;/&#8211;5 ft.</td>
          <td>No</td>
          <td>No</td>
        </tr>
        <tr className="odd-row">
          <td>Maximum turn</td>
          <td>Any</td>
          <td>Any</td>
          <td>90&ordm;</td>
          <td>45&ordm;</td>
          <td>45&ordm;</td>
        </tr>
        <tr>
          <td>Up angle</td>
          <td>Any</td>
          <td>Any</td>
          <td>60&ordm;</td>
          <td>45&ordm;</td>
          <td>45&ordm;</td>
        </tr>
        <tr className="odd-row">
          <td>Up speed</td>
          <td>Full</td>
          <td>Half</td>
          <td>Half</td>
          <td>Half</td>
          <td>Half</td>
        </tr>
        <tr>
          <td>Down angle</td>
          <td>Any</td>
          <td>Any</td>
          <td>Any</td>
          <td>45&ordm;</td>
          <td>45&ordm;</td>
        </tr>
        <tr className="odd-row">
          <td>Down speed</td>
          <td>Double</td>
          <td>Double</td>
          <td>Double</td>
          <td>Double</td>
          <td>Double</td>
        </tr>
        <tr>
          <td className="last-row">Between down and up</td>
          <td className="last-row">0</td>
          <td className="last-row">0</td>
          <td className="last-row">5 ft.</td>
          <td className="last-row">10 ft.</td>
          <td className="last-row">20 ft.</td>
        </tr>
      </tbody>
    </table>
    <a id="evasion-and-pursuit"></a><h5>EVASION AND PURSUIT</h5>
    <p className="initial">In round-by-round movement, simply counting
off squares, it&#8217;s impossible for a slow character to get away from a
determined fast character without mitigating circumstances. Likewise,
it&#8217;s no problem for a fast character to get away from a slower one. </p>
    <p>When the speeds of the two concerned characters are equal,
there&#8217;s a simple way to resolve a chase: If one creature is pursuing
another, both are moving at the same speed, and the chase continues for
at least a few rounds, have them make opposed Dexterity checks to see
who is the faster over those rounds. If the creature being chased wins,
it escapes. If the pursuer wins, it catches the fleeing creature. </p>
    <p>Sometimes a chase occurs overland and could last all day, with
the two sides only occasionally getting glimpses of each other at a
distance. In the case of a long chase, an opposed Constitution check
made by all parties determines which can keep pace the longest. If the
creature being chased rolls the highest, it gets away. If not, the
chaser runs down its prey, outlasting it with stamina.</p>
    <a id="moving-around-in-squares"></a><h5>MOVING AROUND IN SQUARES</h5>
    <p className="initial">In general, when the characters aren&#8217;t engaged
in round-by-round combat, they should be able to move anywhere and in
any manner that you can imagine real people could. A 5-foot square, for
instance, can hold several characters; they just can&#8217;t all fight
effectively in that small space. The rules for movement are important
for combat, but outside combat they can impose unnecessary hindrances
on character activities.</p>
    <a id="exploration"></a><h3>EXPLORATION</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <h5><a id="vision-and-light"></a>VISION AND LIGHT</h5>
    <p className="initial">Dwarves and half-orcs have darkvision, but
everyone else needs light to see by. See Table: Light Sources and
Illumination for the radius that a light source illuminates and how
long it lasts.</p>
    <p>In an area of bright light, all characters can see clearly. A
creature can&#8217;t hide in an area of bright light unless it is <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>
or has cover.</p>
    <p>In an area of shadowy illumination, a character can see dimly.
Creatures within this area have concealment relative to that character.
A creature in an area of shadowy illumination can make a Hide check to
conceal itself.</p>
    <p>In areas of darkness, creatures without darkvision are
effectively <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a>. In addition to the
obvious effects, a blinded
creature has a 50% miss chance in combat (all opponents have total
concealment), loses any Dexterity bonus to AC, takes a &#8211;2 penalty to
AC, moves at half speed, and takes a &#8211;4 penalty on <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
checks and
most Strength and Dexterity-based skill checks.</p>
    <p>Characters with low-light vision (elves, gnomes, and
half-elves) can see objects twice as far away as the given radius.
Double the effective radius of bright light and of shadowy illumination
for such characters.</p>
    <p>Characters with darkvision (dwarves and half-orcs) can see lit
areas normally as well as dark areas within 60 feet. A creature can&#8217;t
hide within 60 feet of a character with darkvision unless it is
invisible or has cover.</p>
    <a id="table-light-sources-and-illumination"></a><p><b>Table: LIGHT SOURCES AND ILLUMINATION</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>Object</th>
          <th>Bright</th>
          <th>Shadowy</th>
          <th>Duration</th>
        </tr>
        <tr className="odd-row">
          <td>Candle</td>
          <td>n/a<sup>1</sup></td>
          <td>5 ft.</td>
          <td>1 hr.</td>
        </tr>
        <tr>
          <td>Everburning torch</td>
          <td>20 ft.</td>
          <td>40 ft.</td>
          <td>Permanent</td>
        </tr>
        <tr className="odd-row">
          <td>Lamp, common</td>
          <td>15 ft.</td>
          <td>30 ft.</td>
          <td>6 hr./pint</td>
        </tr>
        <tr>
          <td>Lantern, bullseye<sup>2</sup></td>
          <td>60-ft. cone</td>
          <td>120-ft. cone</td>
          <td>6 hr./pint</td>
        </tr>
        <tr className="odd-row">
          <td>Lantern, hooded</td>
          <td>30 ft.</td>
          <td>60 ft.</td>
          <td>6 hr./pint</td>
        </tr>
        <tr>
          <td>Sunrod</td>
          <td>30 ft.</td>
          <td>60 ft.</td>
          <td>6 hr.</td>
        </tr>
        <tr className="odd-row">
          <td>Torch</td>
          <td>20 ft.</td>
          <td>40 ft.</td>
          <td>1 hr.</td>
        </tr>
        <tr>
          <td>Spell</td>
          <td>Bright</td>
          <td>Shadowy</td>
          <td>Duration</td>
        </tr>
        <tr className="odd-row">
          <td>Continual flame</td>
          <td>20 ft.</td>
          <td>40 ft.</td>
          <td>Permanent</td>
        </tr>
        <tr>
          <td>Dancing lights (torches)</td>
          <td>20 ft. (each)</td>
          <td>40 ft. (each)</td>
          <td>1 min.</td>
        </tr>
        <tr className="odd-row">
          <td>Daylight</td>
          <td>60 ft.</td>
          <td>120 ft.</td>
          <td>30 min.</td>
        </tr>
        <tr>
          <td className="last-row">Light</td>
          <td className="last-row">20 ft.</td>
          <td className="last-row">40 ft.</td>
          <td className="last-row">10 min.</td>
        </tr>
        <tr>
          <td colSpan="4">1 <i>A candle does not provide bright
illumination, only shadowy illumination.</i></td>
        </tr>
        <tr>
          <td colSpan="4">2 <i>A bullseye lantern illuminates a
cone, not a radius.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="breaking-and-entering"></a><h5>BREAKING AND ENTERING</h5>
    <p className="initial"><b>When attempting to break an object, you
have two choices</b>: smash it with a weapon or break it with sheer
strength.</p>
    <a id="smashing-and-object"></a><h6>Smashing an Object</h6>
    <p className="initial">Smashing a weapon or shield with a slashing or
bludgeoning weapon is accomplished by the <a href="specialAttacks.html#sunder" style={{
        "color": "rgb(87, 158, 182)"
      }}>sunder</a>
special attack.
Smashing an object is a lot like sundering a weapon or shield, except
that your attack roll is opposed by the object&#8217;s AC. Generally, you can
smash an object only with a bludgeoning or slashing weapon.</p>
    <a id="object-armor-class"></a><p><b>Armor Class</b>: Objects are easier to hit than creatures
because they usually don&#8217;t move, but many are tough enough to shrug off
some damage from each blow. An object&#8217;s Armor Class is equal to 10 +
its size modifier + its Dexterity modifier. An inanimate object has not
only a Dexterity of 0 (&#8211;5 penalty to AC), but also an additional &#8211;2
penalty to its AC. Furthermore, if you take a full-round action to line
up a shot, you get an automatic hit with a melee weapon and a +5 bonus
on attack rolls with a ranged weapon.</p>
    <a id="object-hardness"></a><p><b>Hardness</b>: Each object has hardness&#8212;a number that
represents how well it resists damage. Whenever an object takes damage,
subtract its hardness from the damage. Only damage in excess of its
hardness is deducted from the object&#8217;s hit points (see Table: Common
Armor, Weapon, and Shield Hardness and Hit Points; Table: Substance
Hardness and Hit Points; and Table: Object Hardness and Hit Points).</p>
    <a id="object-hit-points"></a><p><b>Hit Points</b>: An object&#8217;s hit point total depends on what
it is made of and how big it is (see Table: Common Armor, Weapon, and
Shield Hardness and Hit Points; Table: Substance Hardness and Hit
Points; and Table: Object Hardness and Hit Points). When an object&#8217;s
hit points reach 0, it&#8217;s ruined.</p>
    <p>Very large objects have separate hit point totals for
different sections.</p>
    <a id="object-energy-attacks"></a><p><i>Energy Attacks</i>: Acid and sonic attacks deal damage to
most objects just as they do to creatures; roll damage and apply it
normally after a successful hit. Electricity and fire attacks deal half
damage to most objects; divide the damage dealt by 2 before applying
the hardness. Cold attacks deal one-quarter damage to most objects;
divide the damage dealt by 4 before applying the hardness.</p>
    <a id="object-ranged-damage"></a><p><i>Ranged Weapon Damage</i>: Objects take half damage from
ranged weapons (unless the weapon is a siege engine or something
similar). Divide the damage dealt by 2 before applying the object&#8217;s
hardness.</p>
    <a id="object-ineffective-weapons"></a><p><i>Ineffective Weapons</i>: Certain weapons just can&#8217;t
effectively deal damage to certain objects.</p>
    <a id="object-immunities"></a><p><i>Immunities</i>: Objects are immune to nonlethal damage and
to critical hits.</p>
    <p>Even animated objects, which are otherwise considered
creatures, have these immunities because they are constructs.</p>
    <a id="smashing-magic-armor-shields-and-weapons"></a><p><i>Magic Armor, Shields, and Weapons</i>: Each +1 of
enhancement bonus adds 2 to the hardness of armor, a weapon, or a
shield and +10 to the item&#8217;s hit points.</p>
    <a id="object-vulnerability-to-certain-attacks"></a><p><i>Vulnerability to Certain Attacks</i>: Certain attacks are
especially successful against some objects. In such cases, attacks deal
double their normal damage and may ignore the object&#8217;s hardness.</p>
    <a id="damaged-objects"></a><p><i>Damaged Objects</i>: A damaged object remains fully
functional until the item&#8217;s hit points are reduced to 0, at which point
it is destroyed.</p>
    <p>Damaged (but not destroyed) objects can be repaired with the <a href="SkillsAll.html#Craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
skill.</p>
    <a id="object-saving-throws"></a><p><b>Saving Throws</b>: Nonmagical, unattended items never make
saving throws. They are considered to have failed their saving throws,
so they always are affected by spells. An item attended by a character
(being grasped, touched, or worn) makes saving throws as the character
(that is, using the character&#8217;s saving throw bonus).</p>
    <p>Magic items always get saving throws. A magic item&#8217;s
Fortitude, Reflex, and Will save bonuses are equal to 2 + one-half its
caster level. An attended magic item either makes saving throws as its
owner or uses its own saving throw bonus, whichever is better.</p>
    <a id="animated-objects"></a><p><i>Animated Objects</i>: Animated objects count as creatures
for purposes of determining their Armor Class (do not treat them as
inanimate objects).</p>
    <a id="breaking-items"></a><h6>Breaking Items</h6>
    <p className="initial">When a character tries to break something with
sudden force rather than by dealing damage, use a Strength check
(rather than an attack roll and damage roll, as with the <a href="specialAttacks.html#sunder" style={{
        "color": "rgb(87, 158, 182)"
      }}>sunder</a>
special
attack) to see whether he or she succeeds. The DC depends more on the
construction of the item than on the material.</p>
    <p>If an item has lost half or more of its hit points, the DC to
break it drops by 2.</p>
    <p>Larger and smaller creatures get size bonuses and size
penalties on Strength checks to break open doors as follows: Fine &#8211;16,
Diminutive &#8211;12, Tiny &#8211;8, Small &#8211;4, Large +4, Huge +8, Gargantuan +12,
Colossal +16.</p>
    <p>A crowbar or portable ram improves a character&#8217;s chance of
breaking open a door.</p>
    <a id="table-common-armor-weapon-and-shield-hardness-and-hit-points"></a><p><b>Table: COMMON ARMOR, WEAPON, AND SHIELD HARDNESS AND HIT
POINTS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>Weapon or Shield</th>
          <th>Hardness</th>
          <th>HP<sup>1</sup></th>
        </tr>
        <tr className="odd-row">
          <td>Light blade</td>
          <td>10</td>
          <td>2</td>
        </tr>
        <tr>
          <td>One-handed blade</td>
          <td>10</td>
          <td>5</td>
        </tr>
        <tr className="odd-row">
          <td>Two-handed blade</td>
          <td>10</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Light metal-hafted weapon</td>
          <td>10</td>
          <td>10</td>
        </tr>
        <tr className="odd-row">
          <td>One-handed metal-hafted weapon</td>
          <td>10</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Light hafted weapon</td>
          <td>5</td>
          <td>2</td>
        </tr>
        <tr className="odd-row">
          <td>One-handed hafted weapon</td>
          <td>5</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Two-handed hafted weapon</td>
          <td>5</td>
          <td>10</td>
        </tr>
        <tr className="odd-row">
          <td>Projectile weapon</td>
          <td>5</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Armor</td>
          <td>special<sup>2</sup></td>
          <td>armor bonus x 5</td>
        </tr>
        <tr className="odd-row">
          <td>Buckler</td>
          <td>10</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Light wooden shield</td>
          <td>5</td>
          <td>7</td>
        </tr>
        <tr className="odd-row">
          <td>Heavy wooden shield</td>
          <td>5</td>
          <td>15</td>
        </tr>
        <tr>
          <td>Light steel shield</td>
          <td>10</td>
          <td>10</td>
        </tr>
        <tr className="odd-row">
          <td>Heavy steel shield</td>
          <td>10</td>
          <td>20</td>
        </tr>
        <tr>
          <td className="last-row">Tower shield</td>
          <td className="last-row">5</td>
          <td className="last-row">20</td>
        </tr>
        <tr>
          <td colSpan="3">1 <i>The hp value given is for Medium
armor, weapons, and shields. Divide by 2 for each size category of the
item smaller than Medium, or multiply it by 2 for each size category
larger than Medium.</i></td>
        </tr>
        <tr>
          <td colSpan="3">2 <i>Varies by material; see Table:
Substance Hardness and Hit Points.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="table-substance-hardness-and-hit-points"></a><p><b>Table: SUBSTANCE HARDNESS AND HIT POINTS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>Substance</th>
          <th>Hardness</th>
          <th>Hit Points</th>
        </tr>
        <tr className="odd-row">
          <td>Paper or cloth</td>
          <td>0</td>
          <td>2/inch of thickness</td>
        </tr>
        <tr>
          <td>Rope</td>
          <td>0</td>
          <td>2/inch of thickness</td>
        </tr>
        <tr className="odd-row">
          <td>Glass</td>
          <td>1</td>
          <td>1/inch of thickness</td>
        </tr>
        <tr>
          <td>Ice</td>
          <td>0</td>
          <td>3/inch of thickness</td>
        </tr>
        <tr className="odd-row">
          <td>Leather or hide</td>
          <td>2</td>
          <td>5/inch of thickness</td>
        </tr>
        <tr>
          <td>Wood</td>
          <td>5</td>
          <td>10/inch of thickness</td>
        </tr>
        <tr className="odd-row">
          <td>Stone</td>
          <td>8</td>
          <td>15/inch of thickness</td>
        </tr>
        <tr>
          <td>Iron or steel</td>
          <td>10</td>
          <td>30/inch of thickness</td>
        </tr>
        <tr className="odd-row">
          <td>Mithral</td>
          <td>15</td>
          <td>30/inch of thickness</td>
        </tr>
        <tr>
          <td className="last-row">Adamantine</td>
          <td className="last-row">20</td>
          <td className="last-row">40/inch of thickness</td>
        </tr>
      </tbody>
    </table>
    <a id="table-size-and-armor-class-of-objects"></a><p><b>Table: SIZE AND ARMOR CLASS OF OBJECTS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "350px"
    }}>
      <tbody>
        <tr>
          <th>Size</th>
          <th>AC Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Colossal</td>
          <td>&#8211;8</td>
        </tr>
        <tr>
          <td>Gargantuan</td>
          <td>&#8211;4</td>
        </tr>
        <tr className="odd-row">
          <td>Huge</td>
          <td>&#8211;2</td>
        </tr>
        <tr>
          <td>Large</td>
          <td>&#8211;1</td>
        </tr>
        <tr className="odd-row">
          <td>Medium</td>
          <td>+0</td>
        </tr>
        <tr>
          <td>Small</td>
          <td>+1</td>
        </tr>
        <tr className="odd-row">
          <td>Tiny</td>
          <td>+2</td>
        </tr>
        <tr>
          <td>Diminutive</td>
          <td>+4</td>
        </tr>
        <tr className="odd-row">
          <td>Fine</td>
          <td>+8</td>
        </tr>
      </tbody>
    </table>
    <a id="table-object-hardness-and-hit-points"></a><p><b>Table: OBJECT HARDNESS AND HIT POINTS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>Object</th>
          <th>Hardness</th>
          <th>Hit Points</th>
          <th>Break DC</th>
        </tr>
        <tr className="odd-row">
          <td>Rope (1 inch diam.)</td>
          <td>0</td>
          <td>2</td>
          <td>23</td>
        </tr>
        <tr>
          <td>Simple wooden door</td>
          <td>5</td>
          <td>10</td>
          <td>13</td>
        </tr>
        <tr className="odd-row">
          <td>Small chest</td>
          <td>5</td>
          <td>1</td>
          <td>17</td>
        </tr>
        <tr>
          <td>Good wooden door</td>
          <td>5</td>
          <td>15</td>
          <td>18</td>
        </tr>
        <tr className="odd-row">
          <td>Treasure chest</td>
          <td>5</td>
          <td>15</td>
          <td>23</td>
        </tr>
        <tr>
          <td>Strong wooden door</td>
          <td>5</td>
          <td>20</td>
          <td>23</td>
        </tr>
        <tr className="odd-row">
          <td>Masonry wall (1 ft. thick)</td>
          <td>8</td>
          <td>90</td>
          <td>35</td>
        </tr>
        <tr>
          <td>Hewn stone (3 ft. thick)</td>
          <td>8</td>
          <td>540</td>
          <td>50</td>
        </tr>
        <tr className="odd-row">
          <td>Chain</td>
          <td>10</td>
          <td>5</td>
          <td>26</td>
        </tr>
        <tr>
          <td>Manacles</td>
          <td>10</td>
          <td>10</td>
          <td>26</td>
        </tr>
        <tr className="odd-row">
          <td>Masterwork manacles</td>
          <td>10</td>
          <td>10</td>
          <td>28</td>
        </tr>
        <tr>
          <td className="last-row">Iron door (2 in. thick)</td>
          <td className="last-row">10</td>
          <td className="last-row">60</td>
          <td className="last-row">28</td>
        </tr>
      </tbody>
    </table>
    <a id="table-dcs-to-break-or-burst-items"></a><p><b>Table: DCs TO BREAK OR BURST ITEMS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "350px"
    }}>
      <tbody>
        <tr>
          <th>Strength Check to:</th>
          <th>DC</th>
        </tr>
        <tr className="odd-row">
          <td>Break down simple door</td>
          <td>13</td>
        </tr>
        <tr>
          <td>Break down good door</td>
          <td>18</td>
        </tr>
        <tr className="odd-row">
          <td>Break down strong door</td>
          <td>23</td>
        </tr>
        <tr>
          <td>Burst rope bonds</td>
          <td>23</td>
        </tr>
        <tr className="odd-row">
          <td>Bend iron bars</td>
          <td>24</td>
        </tr>
        <tr>
          <td>Break down barred door</td>
          <td>25</td>
        </tr>
        <tr className="odd-row">
          <td>Burst chain bonds</td>
          <td>26</td>
        </tr>
        <tr>
          <td>Break down iron door</td>
          <td>28</td>
        </tr>
        <tr>
          <th>Condition</th>
          <th>DC Adjustment<sup>1</sup></th>
        </tr>
        <tr className="odd-row">
          <td>Hold portal</td>
          <td>+5</td>
        </tr>
        <tr>
          <td className="last-row">Arcane lock</td>
          <td className="last-row">+10</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>If both apply, use the larger number.</i></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      